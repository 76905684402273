<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    color="primary"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item three-line>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img :src="profile.imgAvatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="text-h6"
            v-text="profile.title"
          ></v-list-item-title>
          <v-list-item-subtitle
            class="text-h8"
            v-text="profile.role"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            class="text-h7"
            v-text="profile.subtitle"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <v-list expand nav>
        <template v-for="(item, i) in items">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>
          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </v-list>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState, mapActions } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "/dashboard",
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {},

    profile() {
      return {
        avatar: true,
        imgAvatar: "/img/acara_logo_2.png",
        /*imgAvatar:
																																																																																																          "https://ui-avatars.com/api/?name=" + this.$store.state.user.role,*/
        title: this.$store.state.user.username,
        //title: this.$keycloak.tokenParsed.preferred_username,
        //subtitle: "MATRICE LOGIN",
        subtitle: this.$appName,
        //role: "Perfil:" + this.$store.state.user.roles,
        role: this.$store.state.user.name,
      };
    },
  },
  created() {
    this.signIn();
    this.setNavDrawer();
  },
  methods: {
    ...mapActions({
      signIn: "login",
      signOut: "logout",
    }),
    setNavDrawer() {
      //acceso a privs de usuarios
      if (this.$can(["usuarios_list"]) === true) {
        let childrens = Array();
        childrens.push({ title: "Usuarios", to: "usuarios" });
        this.items.push({
          title: "Usuarios",
          icon: "",
          group: "/usuarios",
          children: childrens,
        });

        this.items.map(this.mapItem);
      }
      //acceso a privs de roles
      if (this.$can(["roles_list"]) === true) {
        let childrens = Array();
        childrens.push({ title: "Roles", to: "roles" });
        this.items.push({
          title: "Roles",
          icon: "",
          group: "/roles",
          children: childrens,
        });

        this.items.map(this.mapItem);
      }
      //acceso a privs de permisos
      if (this.$can(["permisos_list"]) === true) {
        let childrens = Array();
        childrens.push({ title: "Permisos", to: "permisos" });
        this.items.push({
          title: "Permisos",
          icon: "",
          group: "/permisos",
          children: childrens,
        });
        this.items.map(this.mapItem);
      }
      //acceso a privs de entidades
      if (
        this.$can([
          "entidades_list",
          "tipo_entidades_list",
          "entidades_roles_list",
        ]) === true
      ) {
        let childrens = Array();
        if (this.$can(["entidades_list"])) {
          childrens.push({ title: "Entidades", to: "entidades" });
        }
        /*if (this.$can(["entidades_roles_list"])) {
          childrens.push({ title: "Entidades Roles", to: "entidades_roles" });
        }*/
        if (this.$can(["tipo_entidades_list"])) {
          childrens.push({ title: "Tipo Entidad", to: "tipo_entidad" });
        }

        this.items.push({
          title: "Entidades",
          icon: "",
          group: "/entidades",
          children: childrens,
        });

        this.items.map(this.mapItem);
      }
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    modificarTitleMenu(to, cant) {
      this.items[4].children.forEach((item) => {
        if (item.to === to) {
          //item.title = newTitle;
          item.cant = cant;
        }
      });
    },
    mensajes() {
      let cant = "";

      this.$axiosApi
        .get("mensajes_recibidos")
        .then((r) => {
          if (r.data.data.length > 0) {
            cant = r.data.data.length;
            this.modificarTitleMenu("recibidos", cant);
          }
        })
        .catch(function (error) {});
      let cantN = "";
      this.$axiosApi
        .get("noticias")
        .then((r) => {
          if (r.data.data.length > 0) {
            cantN = r.data.data.length;
            this.modificarTitleMenu("noticias", cantN);
          }
        })
        .catch(function (error) {});

      let cantE = "";
      let cont = 0;
      this.$axiosApi
        .get("mensajes_enviados")
        .then((r) => {
          if (r.data.data.length > 0) {
            r.data.data.forEach((item) => {
              if (item.estado === "enviado") {
                cont++;
              }
            });

            if (cont > 0) cantE = cont;
            this.modificarTitleMenu("enviados", cantE);
          }
        })
        .catch(function (error) {});
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
</style>
